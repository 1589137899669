import React from 'react';
import MarkdownView from 'react-showdown';
import {Link} from 'gatsby';
import {Row, Col} from 'reactstrap';
import Slider from '../../components/Slider';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import './style.scss';

export default function BrandPage(props){

  const imageArray = props.pageContext.Image.map(img => {
    img.altText = img.alternativeText;
    img.src = img.url;
    return img;
  });

  return(
    <Layout>
      <SEO
        title={props.pageContext.Title}
        description={props.pageContext.Description}
      />
      <div className="BrandPage p-4 bg-light clearfix">
        <p><strong>Go back to:</strong>{' '}
          {props.pageContext.sub_categories.map(item=>{
            return(
            <>
            {' '}
            <Link to={`/sub-categories/${item.Slug}`}>
              {item.Category}
            </Link>
            </>
            )
          })}</p>
        <h3 className="text-center">
          {props.pageContext.Title}
        </h3>
        <Row className="full-height">
          <Col md={6}>
            <MarkdownView
              markdown={props.pageContext.Body}
            />
          </Col>
          <Col md={6}>
            <Slider 
              items={imageArray} 
              id='Brandpage-slider'
            />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};